import * as React from 'react'
import * as styles from '../components/research-list.module.scss'
import GlobalWrapper from '../components/GlobalWrapper'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import SEO from '../components/SEO'

const IndexPage = data => {
  const researchData = data.data.allMarkdownRemark.edges
  return (
    <>
      <SEO
        description="これは公立はこだて未来大学における柳研究室20年間の歩みをまとめたアーカイブサイトの研究成果一覧ページです。"
        title="RESEARCHS"
      />
      <main className={styles.container}>
        <GlobalWrapper>
          <div className={styles.wrapper}>
            <h1 className={styles.title}>RESEARCHS</h1>
            <div className={styles.researchItems}>
              {researchData.map(item => (
                <Link
                  to={'/researchs/' + item.node.frontmatter.key}
                  className={styles.researchItem}
                  key={item.node.frontmatter.key}
                >
                  <GatsbyImage
                    className={styles.researchItemImg}
                    image={getImage(
                      item.node.frontmatter.researchImages[0].childImageSharp
                        .gatsbyImageData
                    )}
                    alt="研究のサムネイル"
                  />
                  <div className={styles.researchDesc}>
                    <p className={styles.researchName}>
                      {item.node.frontmatter.title}
                    </p>
                    <div className={styles.flexWrapper}>
                      <p className={styles.studentName}>
                        {item.node.frontmatter.researchers}
                        <span>
                          （{item.node.frontmatter.graduateYear}年度卒業）
                        </span>
                      </p>
                      <div className={styles.tags}>
                        {item.node.frontmatter.tags.map(tag => (
                          <p className={styles.tag} key={tag}>
                            {tag}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
              <div className={styles.researchItem}></div>
            </div>
          </div>
        </GlobalWrapper>
      </main>
    </>
  )
}

export const query = graphql`
  query MyQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/researchs/" } }
      sort: { order: ASC, fields: frontmatter___graduateYear }
    ) {
      edges {
        node {
          frontmatter {
            title
            key
            researchers
            graduateYear
            researchImages {
              childImageSharp {
                gatsbyImageData(width: 100)
              }
            }
            tags
          }
        }
      }
    }
  }
`

export default IndexPage
