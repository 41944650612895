// extracted by mini-css-extract-plugin
export var container = "research-list-module--container--1S4Xx";
export var wrapper = "research-list-module--wrapper--1wVlA";
export var title = "research-list-module--title---SmZh";
export var researchItems = "research-list-module--researchItems--1I1HA";
export var researchItem = "research-list-module--researchItem--2IIRs";
export var researchDesc = "research-list-module--researchDesc--a1k2C";
export var researchItemImg = "research-list-module--researchItemImg--2njze";
export var researchName = "research-list-module--researchName--3J7Ap";
export var studentName = "research-list-module--studentName--1ms3y";
export var flexWrapper = "research-list-module--flexWrapper--30g2l";
export var tags = "research-list-module--tags--3gRzt";
export var tag = "research-list-module--tag--I73u3";